@font-face {
	font-family: Source;
	font-style: normal;
	font-weight: 400;
	src: url('./assets/SourceSansPro-Regular.ttf');
}
@font-face {
	font-family: Source;
	font-style: normal;
	font-weight: 300;
	src: url('./assets/SourceSansPro-Light.ttf');
}
@font-face {
	font-family: Source;
	font-style: normal;
	font-weight: 200;
	src: url('./assets/SourceSansPro-ExtraLight.ttf');
}

* {
  user-drag: none; 
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  overscroll-behavior-y: none;
}
.App {
  text-align: center;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  min-height: -moz-available;
  min-height: fill-available;
  overflow: hidden;
}

.App-header {
  background: linear-gradient(0deg, rgb(194, 193, 193) 30%, rgba(255,255,255,1) 90%);
  overflow: hidden;
  position: fixed;
  height: 70%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.obj-container {
  position: fixed;
  pointer-events: none;
  bottom: 5%;
  left: 35%;
  z-index: 2;
}
._360deg{
  transform: rotate3d(0, 1, 0, 180deg);
  width: 500px;
}
._0deg{
  width: 500px;
}
@media (min-width: 2000px) {
  .obj-container {
    left: 45%;
  }
}
@media (max-width: 500px) {
  .obj-container {
    bottom: 8%;
    left: auto;
  }
  ._360deg{
    width: 300px;
  }
  ._0deg{
    width: 300px;
  }
}

.line {
  position: fixed;
  width: 100%;
  border-bottom: 2px solid black;
  margin-top: 100px;
}

.board {
  display: flex;
  justify-content: center;
  align-items: center;
  right: 10px;
  width: 300px;
  height: 300px;
  position: fixed;
  margin-top: 20vh;
  transition: 500ms;
  cursor: pointer;
  background-repeat: no-repeat !important;
  background-size: contain !important;

  -webkit-box-shadow: 4px 4px 14px -8px rgb(0 0 0 / 75%);
  -moz-box-shadow: 4px 4px 14px -8px rgb(0 0 0 / 75%);
  box-shadow: 4px 4px 14px -8px rgb(0 0 0 / 75%);
}
.board.shadow {
  -webkit-box-shadow: 10px 10px 26px -10px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 26px -10px rgba(0,0,0,0.75);
  box-shadow: 10px 10px 26px -10px rgba(0,0,0,0.75);
}
.show-info > img {
  display: flex !important
}
.open-info {
  z-index: 30;
  position: absolute;
  top: -70px;
  right: 50%;
  transform: translateX(50%);
  animation-name: example;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
.open-info > img {
  display: none;
  transition: 300ms;
  cursor: pointer;
}
.open-info > img:hover{
  transform: scale(1.1);
}

.arrow-key-container {
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 20px;
  right: 20px;

  user-drag: none; 
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  /* transform: translateX(50%); */
}

.icon-container{
  position: relative;
  border-radius: 8px;
  border: solid 2px black;
  width: 180px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 300ms;
  cursor: pointer;
  user-drag: none; 
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  font-family: Source;
  font-size: 22px;
  font-weight: 500;
}
.icon-container > img {
  height: 36px;
  object-fit: cover;
  user-drag: none; 
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  opacity: 0;
  transition: 1500ms;
}
.icon-container.active {
  background-color: beige;
}
.showw {
  opacity: 1 !important;
}
.icon-container > span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
} 
.replay{
  position: fixed;
  top: 10px;
  right: 10px;
  width: 42px;
  height: 42px;
  z-index: 21;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #000;
  border-radius: 8px;
  cursor: pointer;
  padding: 8px;
  box-sizing: border-box;
}
.email{
  position: fixed;
  top: 10px;
  left: 60px;
  width: 42px;
  height: 42px;
  z-index: 21;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #000;
  border-radius: 8px;
  cursor: pointer;
  padding: 8px;
  box-sizing: border-box;
}
.light{
  position: fixed;
  top: 10px;
  left: 10px;
  width: 40px;
  height: 32px;
  z-index: 21;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #000;
  padding-top: 8px;
  border-radius: 8px;
  cursor: pointer;
}
.just-click{
  position: absolute;
  top: 0
}
@keyframes example {
  0%   {top: -70px}
  50%   {top: -80px}
  100% {top: -70px}
}

.logos {
  height: 100px;
  width: 100px;
  object-fit: contain;
  background-repeat: no-repeat;
}

.looster {
  position: fixed;
  top: 0px;
  width: 250px;
  transition: 500ms;
  z-index: 2;
  will-change: transform;
  pointer-events: none;
}

.sign {
  z-index: 1;
  position: fixed;
  margin-top: -100px;
  height: 300px;
  transition: 500ms;
  will-change: transform;
}
.key {
  width: 40px;
  z-index: 1;
  position: fixed;
  top: 50%;
  height: 300px;
  transition: 500ms;
  margin-left: 400px;
  cursor: pointer;
  height: 56px;
  border-radius: 6px;
  right: 300px;
  box-shadow: 2px 2px 8px -4px rgb(0 0 0 / 75%);
}
.info {
  position: fixed;
  top: 10%;
  width: 480px;
  transition: 500ms;
  border-radius: 6px;
  right: 50%;
  font-family: Source;
  color: black;
  padding: 20px;
  transform: translateX(50%);
  font-size: 24px;
  text-align: left;
  box-sizing: border-box;
}
.info-2 {
  margin-top: 12px;
  font-size: 22px;
  font-weight: 300;
}
.info-3 {
  margin-top: 24px;
  font-size: 18px;
  font-weight: 300;
}
.info-4 {
  font-size: 18px;
  font-weight: 400;
  user-select: text;
  -moz-user-select: text;
  -webkit-user-drag: text;
  -webkit-user-select: text;
  -ms-user-select: text;
}
.info-2 > span {
  font-weight: 400;
}
.info-2 > div {
  display: inline;
}
.info-2 > div > span{ 
  font-weight: 400;
}
.key img {
  width: 100%;
}
.key > div {
  position: absolute;
  top: -60px;
  left: 8px;
  color: black;
  font-size: 18px;
  animation-name: example;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
.key > div > img {
  width: 60px;
}
.key > div > span {
  position: absolute;
  left: 9px;
  top: 10px;
}
.resume-modal {
  /* transition: 500ms; */
  position: fixed;
  
  opacity: 0;
  visibility: hidden;

  width: 100% !important;
  height: 100% !important;

  right: 0 !important;
  top: 0;
  z-index: 10000 !important;

  transition: 300ms;

  background-color: #34383C;
}
.opened {
  position: fixed;
  bottom: auto !important;
  top: 50% !important;
  
  flex-direction: column;
  justify-content: flex-start !important;
  align-items: flex-end;
  padding: 20px;
}
.opened::after {
  /* gradient overlay at bottom of modal window */
  
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  pointer-events: none;
  background: transparent;
  background: -webkit-linear-gradient( bottom , #34383c, rgba(52, 56, 60, 0));
  background: linear-gradient(to top, #34383c, rgba(52, 56, 60, 0));
}

.modal-content{
  box-sizing: border-box;
  line-height: 1.6;
  font-family: Source, sans-serif;
  height: 100%;
  width: 100%;
  padding: 4em 10%;
  text-align: left;
  overflow-y: auto;
  -webkit-font-smoothing: antialiased;
  /* transition: visibility 0s linear 0s, opacity 300ms; */
  /* transition: 700ms; */
}

.resume-modal.visible {
  transition: 600ms;

  visibility: visible;
  opacity: 1;
  /* transition: visibility 0s linear 0s, opacity 0ms !important; */
}

.x-mark {
  position: fixed;
  top: 6%;
  right: 6%;
  background-color: rgb(64, 90, 84);
  color: white;
  padding: 10px;
  display: flex;
  border-radius: 100%;
  /* transition: 300ms; */
  cursor: pointer;
}
.x-mark:hover {
  background-color: black;
}

.bottom {
  height: 30%;
  width: 100%;
  background: linear-gradient(0deg, rgb(255, 255, 255) 0%, #cacaca  100%);
  position: fixed;
  bottom: 0;
  left: 0;
}
.overlay {
  pointer-events: none;
  z-index: 20;
  opacity: 0.5;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: black
}


@media only screen and (max-height: 500px) {
  .board {
    max-width: 120px;
    max-height: 120px;
  }
  ._360deg{
    width: 180px;
  }
  ._0deg{
    width: 180px;
  }
  .key {
    margin-left: 200px;
    width: 20px;
    height: 28px;
    border-radius: 2px;
  }
  .looster {
    height: 120px;
    width: 120px;
  }
}
@media only screen and (max-width: 500px) {
  .looster {
    height: 150px;
    width: 150px;
  }
  .key {
    width: 20px;
    height: 28px;
    border-radius: 2px;
    right: 0
  }
  .info {
    width: calc(100% - 20px);
    right: 250px;
    font-size: 18px;
    padding: 10px;
  }
  .info-2 {
    font-size: 16px;
  }
  .info-3 {
    font-size: 16px;
  }
  .info-4 {
    font-size: 16px;
  }
  .info-2 > div {
    display: none;
  }
  .key > div {
    position: absolute;
    top: -20px;
    left: 4px;
    margin-top: 4px;
    font-size: 12px;
  }
  .key > div > span {
    position: absolute;
    left: 5px;
    top: 6px;
  }
  .key > div > img {
    width: 40px;
  }
  .icon-container{
    width: calc(100vw - 40px);
  }
  .board {
    max-width: 200px;
    max-height: 200px;
  }
  .open-info > img {
    width: 40px;
  }

  .arrow-key-container {
    right: 50%;
    transform: translateX(50%);
  }

  @keyframes example {
    0%   {top: -50px}
    50%   {top: -60px}
    100% {top: -50px}
  }
}
